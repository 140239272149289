import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {Campaign} from "../core/CampaignAdapter";

@Injectable()
export class CampaignInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const campaign = this.cookieService.check('selectedCampaign') ? JSON.parse(this.cookieService.get('selectedCampaign')) as Campaign : undefined;

    if (campaign) {
      const headers = request.headers.set('Campaign-Id', campaign.id.toString());
      let url = request.url;

      const itemToChange = '{campaignId}';
      const newItem = campaign?.id.toString();

      if (url.includes(itemToChange)) {
        url = url.replace(itemToChange, newItem);
      }

      const modifiedReq = request.clone({ url, headers });

      // Forward the modified request
      return next.handle(modifiedReq);
    }

    return next.handle(request);
  }
}
