import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {catchError, delay, Observable, of, retry, throwError} from 'rxjs';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  // Max retry attempts and delay duration (in ms)
  private readonly maxRetries = 3;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry({
        count: this.maxRetries, // Number of retries
        delay: (error: HttpErrorResponse, retryCount: number) => {
          if ([500, 502, 503, 504].includes(error.status)) {
            // Exponential backoff delay (2^retryCount * 1000ms)
            const delayDuration = Math.pow(2, retryCount) * 1000;
            console.log(`Retry attempt #${retryCount}. Waiting ${delayDuration}ms before retrying...`);
            return of(null).pipe(delay(delayDuration));  // Delays retry
          }

          // If the error is not retryable, throw the error immediately
          throw error;
        },
        // Retry only on specific conditions (optional)
        resetOnSuccess: true
      }),
      catchError((error: HttpErrorResponse) => {
        // Handle the error (log, show a notification, etc.)
        console.error('HTTP Request failed after retries:', error.error);
        return throwError(() => new Error(error.error));
      })
    );
  }
}
