import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, timeout} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {Campaign} from "../core/CampaignAdapter";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.cookieService.get('token');
    const campaign = this.cookieService.check('selectedCampaign') ? JSON.parse(this.cookieService.get('selectedCampaign')) as Campaign : undefined;

    if (token) {
      let headers = request.headers.set('Authorization', token);

      if (campaign) {
        headers = headers.set('Campaign-Id', campaign.id.toString());
      }

      const clonedRequest = request.clone({
        headers: headers
      });

      return next.handle(clonedRequest).pipe(
        timeout(60000)
      );
    } else {
      return next.handle(request).pipe(
        timeout(60000)
      );
    }
  }
}
