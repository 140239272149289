import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AuthComponent} from './auth/login/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {TokenInterceptor} from "./interceptor/token.interceptor";
import {AppRoutingModule} from "./app-routing.module";
import {TranslateCompiler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NgSelectModule} from "@ng-select/ng-select";
import {ToastrModule} from "ngx-toastr";
import {TranslateMessageFormatCompiler} from "ngx-translate-messageformat-compiler";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Overlay} from "@angular/cdk/overlay";
import {MAT_DATEPICKER_SCROLL_STRATEGY} from "@angular/material/datepicker";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {RequestCounterInterceptor} from "./interceptor/request-count.interceptor";
import {OnlyDigitsDirective} from "./directive/only-digits.directive";
import {MatButton} from "@angular/material/button";
import {RetryInterceptor} from "./interceptor/retry.interceptor";
import {CampaignInterceptor} from "./interceptor/campaign.interceptor";
import {DateFnsModule} from "@angular/material-date-fns-adapter";
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { enGB } from 'date-fns/locale';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {DatePipe} from "@angular/common";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB'; // Replace 'fr' with your locale

registerLocaleData(localeEnGB);

@NgModule({
  declarations: [
        AppComponent,
        AuthComponent,
    ],
  bootstrap: [AppComponent],
  imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        ToastrModule.forRoot(),
        NgbModule,
        OnlyDigitsDirective,
        MatButton,
        DateFnsModule
  ],
  providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CampaignInterceptor,
          multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestCounterInterceptor,
            multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RetryInterceptor,
          multi: true
        },
        {
            provide: MAT_DATEPICKER_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay]
        },
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi()),
        {provide: MAT_DATE_LOCALE, useValue: enGB},
        {provide: DateAdapter, useClass: DateFnsAdapter,deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
        {provide: LOCALE_ID, useValue: 'en-GB'},
        DatePipe
    ]
})
export class AppModule {
}
